body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fbf9f7;
}

input,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --white: #ffffff;
  --card-background: #ffffff;
  --background: #fbf9f7;
  --input-background: #f9f9f9;
  --button-border: #3e3e3e;
  --divider: #e0e1e6;
  --text-color: #202223;
  --dark-grey: #6a6c6c;
  --light-grey: #a0a0a0;
  --primary: #014440;

  --live-background: #fbf9f7;
  --input-border-radius: 10px;
  --input-border: 1px solid var(--divider);
  --input-padding: 10px;
}

/* .dark-mode {
  --primary: #ffffff;
  --input-background: #202223;
} */

.mdxeditor {
  border: 1px solid var(--divider);
  border-radius: 10px;
}
